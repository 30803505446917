import React from "react"
import {ProtectedDialogRoute} from "../routes"
import {useParams} from "react-router-dom"

const RenderDialogItems = ({ routes, basePath }) => {

  const defaultParams = useParams()

  const renderRoute = ({ component, history, match })  => {
    const Component = component
    const params = (match && match.params) ? {...match.params, ...defaultParams} : {...defaultParams}
    return <Component params={params} show={match != null} onHide = {() => {history.goBack()} } />
  }

  return <>{ Object.keys(routes).map(key => (
    <ProtectedDialogRoute key={key} path={routes[key].path}>
      {({ history, match }) => renderRoute({ component: routes[key].component, history, match })}
    </ProtectedDialogRoute>
  )) }</>
}


export default RenderDialogItems
